<template>
	<div class='course-content'>
		<div>
			<div class="course-top overflow-inherit">
				<div class="float-left overflow-inherit" style="height: 100%;margin-top: 13px;">
					<div :class="isActive == 'first' ? 'tabs-btn-true float-left':'tabs-btn float-left'"
						@click="tabs('first')">待审核</div>
					<div v-if="examine_number > 0"
						:class="isActive == 'first' ? 'examine-number examine-number-active' : 'examine-number examine-number-noactive'">
						{{examine_number}}
					</div>
					<div :class="isActive == 'second' ? 'tabs-btn-true float-left':'tabs-btn float-left'"
						style="margin-left: 20px;" @click="tabs('second')">已审核</div>
				</div>
				<div class="float-right overflow-inherit" style="height: 100%;">
					<el-input :placeholder="'输入编码/' + util.getKeyFrontValue('name') + '/' + util.getKeyFrontValue('category') + '/所属单位/' + util.getKeyFrontValue('apply') + '进行搜索'" class="search-input" v-model="search">
					</el-input>
					<button class="search-btn" type="button" @click="searchList"><i
							class="el-icon-search"></i>搜索</button>
					<button class="reset-btn" type="button" @click="reset">重置</button>
					<button class="excel-btn" type="button" @click="exportExcel">导出详情</button>
					<button class="data-btn" type="button" @click="getStatistics">统计数据</button>
				</div>
			</div>
		</div>
		<div>
			<div class="table-content">
				<el-table :data="tabList" border stripe v-loading="loading"
					:header-cell-style="{background: '#EEF7FE',color:'black'}" style="width: 100%;">
					<el-table-column prop="num" label="编码" align="center" width="100"></el-table-column>
					<el-table-column :label="util.getKeyFrontValue('cover')" width="120" align="center">
						<template slot-scope="scope">
							<el-popover placement="right" title="" trigger="hover" width="250" style="cursor: pointer;">
								<el-image slot="reference" :src="alOssUrl+scope.row.cover+util.imgSize.imgQ40"
									style="height: 75px;width:60px"></el-image>
								<el-image :src="alOssUrl+scope.row.cover+util.imgSize.imgQ40"></el-image>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column prop="name" :label="util.getKeyFrontValue('name')" min-width="200">
						<template slot-scope="scope">
							<div>
								<div class="row-name">{{ scope.row.name }}</div>
								<span v-if="scope.row.course_category_kind == 'labor'" class="labor-label comment-margin-right-5">劳动教育</span>
								<span v-if="scope.row.course_category_kind == 'comprehensive'" class="comprehensive-label comment-margin-right-5">综合实践</span>
								<span class="stage-label">
									{{scope.row.apply_to_stages && scope.row.apply_to_stages.length>0 ? util.setStage(scope.row.apply_to_stages) : ''}}
								</span>
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="course_category_name" :label="util.getKeyFrontValue('category')" align="center" width="120">
						<template slot-scope="scope">
							<div>
								{{scope.row.course_category_name}}
							</div>
						</template>
					</el-table-column> -->
					<el-table-column prop="competition_course_author_affiliation" label="所属单位" align="center" min-width="150">
						<template slot-scope="scope">
							<div>
								{{scope.row.competition_course_author_affiliation}}
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="apply_to_stages" :label="util.getKeyFrontValue('apply')" align="center" width="80">
						<template slot-scope="scope">
							{{scope.row.apply_to_stages && scope.row.apply_to_stages.length > 0 ? util.setStage(scope.row.apply_to_stages) : ''}}
						</template>
					</el-table-column> -->
					<el-table-column prop="confirm_status" label="状态" align="center" width="80">
						<template slot-scope="scope">
							<div>
								<span v-if="(userInfo.county_code == userInfo.region_code) && scope.row.confirm_status == 8 && scope.row.confirm_step == 10">待上级部门审核</span>
								<span v-else>{{util.confirm_status[scope.row.confirm_status].key}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop=""  v-if="competitionConfig && competitionConfig.staff_comment_status==1" label="教师互评/权重占比" align="center" width="90">
						<template slot="header" slot-scope="scope" v-if="competitionConfig">
							教师互评<!-- /权重占比  {{competitionConfig.avg_competition_course_comment_belong_staff+'%'}} -->
							<el-popover placement="top-start" width="200" trigger="hover" content="权重占比: 指教师互评占总分的权重得分">
								<!-- <i slot="reference" class="el-icon-info icon-style"></i> -->
							</el-popover>
						</template>
						<template slot-scope="scope">
							<div>
								{{scope.row.competition_course_comment_belong_staff_average_rate}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop=""  v-if="competitionConfig && competitionConfig.voted_status==1" label="点赞/权重占比" align="center" width="90">
						<template slot="header" slot-scope="scope" v-if="competitionConfig">
							点赞数<!-- /权重占比  {{competitionConfig.voted_num+'%'}} -->
							<el-popover placement="top-start" width="200" trigger="hover" content="权重占比: 指点赞占总分的权重得分">
								<!-- <i slot="reference" class="el-icon-info icon-style"></i> -->
							</el-popover>
						</template>
						<template slot-scope="scope">
							<div>
								{{ scope.row.voted_num ? scope.row.voted_num : 0 }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="" label="专家评分/权重占比" align="center" width="90">
						<template slot="header" slot-scope="scope">
							专家评分<!-- /权重占比   {{competitionConfig.avg_competition_course_comment_belong_competition_course_expert+'%'}} -->
							<el-popover placement="top-start" width="200" trigger="hover" content="权重占比: 指专家评分占总分的权重得分">
<!-- 								<i slot="reference" class="el-icon-info icon-style"></i> -->
							</el-popover>
						</template>
						<template slot-scope="scope">
							<div>
								{{scope.row.competition_course_comment_belong_competition_course_expert_average_rate}}
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="score" label="总分" align="center" width="60">
						<template slot-scope="scope">
							<div>
								{{scope.row.score ? scope.row.score.split('.')[0] + '.' + (scope.row.score.split('.')[1] + '').slice(0,2) : '暂无'}}
							</div>
						</template>
					</el-table-column> -->
					<el-table-column label="操作" width="130" fixed="right" align="center">
						<template slot-scope="scope">
							<el-button v-if="scope.row.confirm_status == 9" type="text"
								style="color: #FF4E00;margin-right: 20px;" @click='seeReason(scope.row)'>查看原因
							</el-button>
							<el-button type="text" style="color: #44D89E;margin-left: 0;"
								@click='detailClick(scope.row)'>详情</el-button>
							<span v-if="scope.row.confirm_status == 8 && scope.row.confirm_step != 0" style="display: inline-block;">
								<el-button v-if="(userInfo.municipal_code == userInfo.region_code) && scope.row.confirm_step != 20" type="text"
									style="color: #FF4E00;margin-left: 20px;" @click='examineClick(scope.row)'>审核
								</el-button>
								<el-button v-if="(userInfo.county_code == userInfo.region_code) && scope.row.confirm_step != 10" type="text"
									style="color: #FF4E00;margin-left: 20px;" @click='examineClick(scope.row)'>审核
								</el-button>
							</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="block">
				<el-pagination v-if='paginationShow' background @size-change="handleSizeChange"
					@current-change="handleCurrentChange" :page-sizes="[5, 10, 15, 20]" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total" :current-page="currentPage">
				</el-pagination>
			</div>
		</div>
		<courseDetail ref="courseDetail" role="government"></courseDetail>
		
		<!-- reject -->
		<el-dialog :visible.sync="rejectModel" title="拒绝" width="500px" top="30vh" center>
			<el-input type="textarea" show-word-limit maxlength="250" v-model="rejectReason"></el-input>
			<span slot="footer" class="dialog-footer">
				<button class="comment-cancel-btn" type="button" @click="rejectCancel">取消</button>
				<button class="comment-confirm-btn" type="button" @click="rejectSure">确定</button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import courseDetail from '../components/courseDetail.vue'
	import {
		reactive,
		ref,
		toRefs,
		watchEffect
	} from 'vue'
	import {
		useRouter
	} from 'vue-router' //引用router
	export default {
		name: 'index',
		components: {
			courseDetail
		},
		data() {
			return {
				region_theme_text: JSON.parse(sessionStorage.getItem('region_theme_text')) ? JSON.parse(sessionStorage.getItem('region_theme_text')) : '课程',
				userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
				config: JSON.parse(sessionStorage.getItem('config')),
				tabList: [],
				pageSize: 5,
				total: 1,
				currentPage: 1,
				paginationShow: false,
				loading: false,
				search: '',
				isActive: 'first',
				// 待审核数量
				examine_number: 0,
				competitionConfig:null,
				
				rejectModel: false,
				rejectId: '',
				rejectReason: ''
			}
		},
		methods: {
			exportExcel() {
				// window.open("http://local.course-competition.com/api/v2.0/download-list?page=1&limit=5&region_code=510000&sn1=8");
				// return
				let obj = this.util.delObjItem({
					page: this.currentPage,
					limit: this.pageSize,
					sf1: this.search,
					region_code: JSON.parse(sessionStorage.getItem('region_code')),
					nav: this.isActive == 'first' ? 'confirming' : 'confirmed'
				})
				obj.DataType = "blob"
				this.http.exportGovernmentExcel(obj)
					.then((response) => {
						console.log(response)
						let blob = new Blob([response.data], {
							type: String(response.headers["content-type"]),
						});
						let fileName = "";
						let fileNameTag="";
						if (
							String(response.headers["content-type"]) ==
							"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						) {
							fileNameTag = ".xlsx";
						} else {
							fileNameTag = ".xls";
						}
						
						if(this.isActive == 'first'){
							fileName="未审核信息表"+fileNameTag
						}else{
							fileName="已审核信息表"+fileNameTag
						}
						
						let elink = document.createElement("a");
						let objectUrl = window.URL.createObjectURL(blob);
						elink.download = fileName; //下载文件名称,
						elink.style.display = "none";
						elink.href = objectUrl;
						document.body.appendChild(elink);
						elink.click();
						URL.revokeObjectURL(elink.href); // 释放URL 对象
						document.body.removeChild(elink);
					})

			},
			getStatistics() {
				this.$router.push({
					name: 'Statistics',
					// query: {
					// 	region_code: JSON.parse(sessionStorage.getItem('region_code')),
					// },
				})
			},
			tabs(name) {
				if (this.isActive != name) {
					this.currentPage = 1
					this.isActive = name
					this.getGovernmentList()
				}
			},
			//获取占比数据
			getConfigInfo(){
				this.http.getConfigInfo('?region_code=' + JSON.parse(sessionStorage.getItem('region_code')),
					).then(
					(res) => {
						// console.log(res)
						if(res && res.data) {
							this.competitionConfig=res.data.data ? res.data.data.competition_course_region_config : null;
						}
						
					})
			},
			// 列表
			getGovernmentList() {
				let obj = this.util.delObjItem({
					page: this.currentPage,
					limit: this.pageSize,
					sf1: this.search,
					nav: this.isActive == 'first' ? 'confirming' : 'confirmed'
				})
				this.loading = true
				this.http.getGovernmentList('?region_code=' + JSON.parse(sessionStorage.getItem('region_code')),
					obj).then(
					(res) => {
						this.tabList = res.data.data.list
						this.loading = false
						this.total = res.data.data.total
						this.paginationShow = true
						if (this.isActive == 'first') {
							this.examine_number = res.data.data.total
						}
					})
			},
			// 搜索
			searchList() {
				this.currentPage = 1
				this.getGovernmentList()
			},
			// 重置
			reset() {
				this.search = ''
				this.currentPage = 1
				this.getGovernmentList()
			},
			editLogin() {
				localStorage.clear()
				sessionStorage.clear()
				// 退出
				this.$router.push({
					name: 'login'
				})
				history.pushState(null, null, document.URL);
				window.addEventListener("popstate", function(e) {
					history.pushState(null, null, document.URL);
				}, false);
			},
			// 详情
			detailClick(row) {
				this.$refs.courseDetail.show(row)
				// this.$router.push({
				// 	name: 'review_detail',
				// 	query: {
				// 		course_id: row.id
				// 	}
				// })
			},
			// 查看原因
			seeReason(row) {
				this.$alert(row.reason, '查看原因', {
					confirmButtonText: '关闭',
					confirmButtonClass: 'confirmCancelClass',
					callback: action => {

					}
				});
			},
			rejectCancel() {
				this.rejectModel = false
				this.rejectReason = ''
			},
			rejectSure() {
				this.http.courseReject('?id=' + this.rejectId, {
					reason: this.rejectReason
				}).then(res => {
					this.$message.success('已拒绝！')
					this.rejectModel = false
					this.rejectReason = ''
					this.getGovernmentList()
				})
			},
			// 审核
			examineClick(row) {
				this.$confirm(`是否对${this.region_theme_text}：[${row.name}]审核通过。`, '审核', {
					distinguishCancelAndClose: true,
					confirmButtonText: '通过',
					cancelButtonText: '拒绝',
					confirmButtonClass: 'confirmClass',
					cancelButtonClass: 'confirmCancelClass'
				}).then(() => {
					// 通过
					this.http.courseConfirm('?id=' + row.id).then(res => {
						this.$message.success('已通过！')
						this.getGovernmentList()
					})
				}).catch((action) => {
					if (action === 'close') {
						return
					} else {
						// 拒绝
						this.rejectId = row.id
						this.rejectModel = true
						// this.$prompt('', '拒绝', {
						// 	confirmButtonText: '确定',
						// 	cancelButtonText: '取消',
						// 	confirmButtonClass: 'confirmClass',
						// 	cancelButtonClass: 'confirmCancelClass',
						// 	inputErrorMessage: '请输入拒绝原因,且最大字数为250',
						// 	inputPlaceholder: '请输入拒绝原因',
						// 	inputType: 'textarea',
						// 	inputShowwordlimit: true,
						// 	inputMaxLength: 250,
						// 	inputValidator: (value) => {
						// 		if (value == null || value == '' || value == undefined || value.length > 250) {
						// 			return false
						// 		} else {
						// 			return true
						// 		}
						// 	},
						// }).then(({
						// 	value
						// }) => {
						// 	this.http.courseReject('?id=' + row.id, {
						// 		reason: value
						// 	}).then(res => {
						// 		this.$message.success('已拒绝！')
						// 		this.getGovernmentList()
						// 	})
						// }).catch(() => {

						// });
					}
				});
			},
			//返回
			edit() {
				this.$router.push(-1)
			},
			//添加
			store() {
				this.$router.push({
					name: 'store'
				})
			},
			//删除
			deleteList(row) {
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let obj = {
						ids: [row.id]
					}
					this.http.deleteList('', obj).then((res) => {
						if (res.data.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							let totalPag = Math.ceil((this.total - 1) / this.pageSize)
							let currtPage = this.currentPage > totalPag ? totalPag : this
								.currentPage
							this.currentPage = currtPage < 1 ? 1 : currtPage
							this.getGovernmentList()
						} else {
							this.$message.error(res.data.msg);
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			//分页
			handleSizeChange(val) {
				this.pageSize = val;
				this.getGovernmentList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				sessionStorage.setItem('currentPage', val);
				this.getGovernmentList();
			},
		},
		mounted() {
			this.getConfigInfo();
			this.getGovernmentList()
		},
		watch: {

		}
	}
</script>

<style scoped="scoped">
	.overflow-inherit {
		overflow: inherit;
	}

	.course-content {
		width: 68%;
		min-width: 1100px;
		height: 73vh;
		margin: 0 auto;
		background-color: white;
		padding: 20px 10px 20px 25px;
		box-sizing: border-box;
		overflow-y: scroll;
	}

	.course-top {
		height: 40px;
		margin-bottom: 20px;
	}

	/*切换样式*/
	.tabs-btn {
		font-size: 16px;
		font-weight: 400;
		color: #999999;
		/* margin-right: 20px; */
		cursor: pointer;
	}

	.examine-number {
		float: left;
		/* width: 30px; */
		margin-left: 3px;
		padding: 0 5px;
		height: 15px;
		border-radius: 30px;
		line-height: 15px;
		font-size: 12px;
		text-align: center;
		position: relative;
		top: -5px;
	}

	.examine-number-active {
		border: 1px solid #FF3B3D;
		color: #FF3B3D;
	}

	.examine-number-noactive {
		border: 1px solid #999999;
		color: #999999;
	}

	.tabs-btn-true {
		position: relative;
		font-size: 16px;
		font-weight: bold;
		color: #FF3B3D;
		/* margin-right: 20px; */
		cursor: pointer;
	}

	.tabs-btn-true::after {
		content: '';
		height: 2px;
		width: 40px;
		display: block;
		position: absolute;
		background-color: #FF3B3D;
		left: 4px;
		bottom: -6px;
	}

	.search-input {
		width: 400px;
		height: 40px;
		margin-right: 30px;
	}

	.search-input /deep/ .el-input__inner {
		height: 38px;
		line-height: 38px;
	}

	.search-btn {
		width: 80px;
		height: 40px;
		background: #FF3B3D;
		box-shadow: 0px 3px 6px rgba(255, 59, 61, 0.16);
		opacity: 1;
		border-radius: 5px;
		font-size: 16px;
		color: #ffffff;
		border: none;
		margin-right: 20px;
		cursor: pointer;
	}
	.search-btn:hover {
		background: #C6191F;
	}
	.search-btn i {
		margin-right: 5px;
	}

	.reset-btn {
		box-sizing: border-box;
		width: 80px;
		height: 40px;
		/* background: rgba(255, 255, 255, 0.39); */
		background: #E8E8E8;
		/* box-shadow: 0px 3px 6px #FF3B3D; */
		opacity: 1;
		border-radius: 5px;
		font-size: 16px;
		/* color: #FF3B3D; */
		color: #333333;
		border: none;
		/* border: 1px solid #FF3B3D; */
		cursor: pointer;
		/* margin-right: 50px; */
	}
	.reset-btn:hover {
		background: #D1D1D1;
	}
	.back-btn {
		box-sizing: border-box;
		/* width: 80px; */
		padding: 0 20px;
		height: 40px;
		line-height: 40px;
		background: #E8E8E8;
		opacity: 1;
		border-radius: 5px;
		font-size: 16px;
		color: #cccccc;
		border: none;
		cursor: pointer;
		margin-right: 23px;
	}
	.back-btn:hover {
		background: #D1D1D1;
	}
	.back-btn img {
		margin-right: 10px;
		width: 13px;
		height: 13px;
		margin-top: 2px;
	}

	.excel-btn {
		/* padding: 0 9px; */
		box-sizing: border-box;
		width: 90px;
		text-align: center;
		height: 40px;
		line-height: 40px;
		background: rgba(24, 190, 45, 1);
		opacity: 1;
		margin-left: 20px;
		border-radius: 5px;
		border: none;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		opacity: 1;
		margin-right: 20px;
		cursor: pointer;
	}
	.excel-btn:hover {
		background: #07AC1C;
	}
	.data-btn {
		box-sizing: border-box;
		width: 90px;
		text-align: center;
		height: 40px;
		line-height: 40px;
		background: rgba(255, 189, 89, 1);
		opacity: 1;
		border-radius: 5px;
		border: none;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		opacity: 1;
		cursor: pointer;
	}
	.data-btn:hover {
		background: #FCA420;
	}
	.icon-style {
		color: #ffffff;
		background-color: #999999 !important;
		border-radius: 50%;
		margin-left: 1px;
	}

	.course-title {
		font-size: 20px;
		font-weight: bold;
		color: #333333;
		letter-spacing: 1px;
		margin-bottom: 15px;
	}

	.el-table td,
	.el-table th {
		padding: 8px 0 !important;
	}

	.table-content {
		/* min-height: 49vh;
		max-height: 48vh; */
		min-height: 49vh;
		overflow-y: auto;
	}

	.table-content /deep/ .el-table td {
		padding: 6px 0 !important
	}

	.table-content /deep/ .el-table th {
		padding: 6px 0 !important
	}

	/* 分页 */
	.block {
		text-align: center;
		margin-top: 20px;
	}

	/*表格hover*/
	/deep/.el-tag {
		border-radius: 0;
		background: none;
		border: none;
		color: black;
		font-size: 14px;
		/*line-height: 60px;*/
	}
	.row-name {
		font-size: 14px;
		line-height: 14px;
		margin-bottom: 10px;
	}
	.labor-label {
		display: inline-block;
		width: 50px;
		height: 14px;
		padding: 2px 0;
		background: #EBFFF7;
		text-align: center;
		border-radius: 1px;
		color: #44D89E;
		font-size: 10px;
		line-height: 14px;
	}
	.comprehensive-label {
		display: inline-block;
		width: 50px;
		height: 14px;
		padding: 2px 0;
		background: #FFF1EB;
		text-align: center;
		border-radius: 1px;
		color: #FB824E;
		font-size: 10px;
		line-height: 14px;
	}
	.stage-label {
		display: inline-block;
		min-width: 50px;
		height: 14px;
		padding: 2px 3px;
		background: #EFF6FF;
		text-align: center;
		border-radius: 1px;
		color: #1950A2;
		font-size: 10px;
		line-height: 14px;
	}
</style>
